.floor-second {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0;
    .first-title {
        font-size: 40px;
        color: #333333;
        letter-spacing: 0;
        text-align: center;
        font-weight: 700;
        margin-bottom: 50px;
    }
    .second-title {
        margin-bottom: 68px;
        max-width: 1100px;
        font-size: 16px;
        color: #333333;
        letter-spacing: 0;
        line-height: 32px;
        font-weight: 400;
    }
}
.floor-third {
    width: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .title {
        font-size: 40px;
        color: #333333;
        letter-spacing: 0;
        text-align: center;
        font-weight: 700;
        margin: 80px 0 50px;
    }
    .card {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 90px;
        .card-item {
            width: 400px;
            box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.1);
            .card-img {
                height: 160px;
                line-height: 160px;
                background: rgba(0, 0, 0, 0.5);
                background-size: cover;
                background-repeat: no-repeat;
                font-size: 24px;
                color: #ffffff;
                letter-spacing: 0;
                text-align: center;
                font-weight: 700;
            }
            .card-content {
                height: 300px;
                font-size: 16px;
                color: #666666;
                letter-spacing: 0;
                line-height: 28px;
                font-weight: 400;
                background-color: #fff;
                padding: 25px 40px;
                text-align: center;
                position: relative;
                .btn {
                    position: absolute;
                    cursor: pointer;
                    bottom: 60px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 200px;
                    height: 44px;
                    line-height: 44px;
                    background: #ffffff;
                    border: 1px solid #d7000f;
                    border-radius: 22px;
                    font-size: 18px;
                    color: #d7000f;
                    letter-spacing: 0;
                    text-align: center;
                    font-weight: 400;
                }
            }
            &:not(:last-child) {
                margin-right: 40px;
            }
        }
    }
}
.floor-fourth {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0 110px 0;
    position: relative;
    .title {
        width: 600px;
        height: 52px;
        font-size: 40px;
        color: #333333;
        letter-spacing: 0;
        text-align: center;
        font-weight: 700;
    }
    .content {
        display: flex;
        position: relative;
        .colum {
            .colum-title {
                font-size: 24px;
                color: #333333;
                letter-spacing: 0;
                line-height: 24px;
                font-weight: 400;
                text-align: center;
                margin-bottom: 35px;
                margin-top: 60px;
            }
            .colum-item {
                width: 400px;
                height: 154px;
                background: #ffffff;
                box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
                padding: 32px 30px 28px;
                transition: all 0.2s linear;
                cursor: pointer;
                .top {
                    display: flex;
                    align-items: center;
                    margin-bottom: 24px;
                    .left {
                        background: #fff;
                        border-radius: 50%;
                    }
                    .right {
                        margin-left: 16px;
                        .right-top {
                            font-size: 18px;
                            color: #333333;
                            letter-spacing: 1px;
                            line-height: 24px;
                            font-weight: 700;
                        }
                        .right-bottom {
                            font-size: 16px;
                            color: #666666;
                            letter-spacing: 0;
                            text-shadow: 0 4px 4px rgba(184, 195, 218, 0.17);
                            font-weight: 400;
                        }
                    }
                }
                .bottom {
                    display: flex;
                    align-items: center;
                    background-color: transparent;
                    .tag {
                        height: 24px;
                        line-height: 24px;
                        background: transparent;
                        border: 1px solid #eeeeee;
                        border-radius: 3px;
                        font-size: 12px;
                        color: #999999;
                        letter-spacing: 0;
                        font-weight: 400;
                        padding: 0 8px;
                        &:not(:last-child) {
                            margin-right: 8px;
                        }
                    }
                }
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
                &:hover {
                    transform: translate(0px, -3px);
                    box-shadow: 0 2px 10px rgba(98, 98, 98, 0.5);
                    background-image: linear-gradient(135deg, #ff1414 0%, #ff7538 100%);
                    .bottom {
                        .tag {
                            color: #eeeeee;
                        }
                    }
                    .right {
                        .right-top,
                        .right-bottom {
                            color: #fff;
                        }
                    }
                }
            }
            &:nth-child(1),
            &:nth-child(2) {
                margin-right: 40px;
            }
        }
        .more {
            position: absolute;
            font-size: 16px;
            color: #666666;
            letter-spacing: 0.4px;
            line-height: 22px;
            font-weight: 400;
            right: 40px;
            bottom: -30px;
        }
    }
}
.floor-firth {
    padding-bottom: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .title {
        font-family: MicrosoftYaHei-Bold;
        font-size: 40px;
        color: #333333;
        letter-spacing: 0;
        text-align: center;
        font-weight: 700;
        margin-top: 60px;
        margin-bottom: 50px;
    }
    .content {
        position: relative;
        .row {
            display: flex;
            align-items: center;
            justify-content: center;
            .card {
                background: #fff;
                width: 400px;
                height: 380px;
                img {
                    width: 400px;
                    height: 200px;
                }
                &-title {
                    font-size: 20px;
                    color: #333333;
                    letter-spacing: 0;
                    text-align: center;
                    margin-top: 16px;
                    margin-bottom: 12px;
                }
                &-content {
                    font-size: 16px;
                    color: #666666;
                    letter-spacing: 0.4px;
                    text-align: justify;
                    line-height: 24px;
                    font-weight: 400;
                    padding: 0 24px 24px;
                }
                &:not(:last-child) {
                    margin-right: 40px;
                }
            }
            &:not(:last-child) {
                margin-bottom: 40px;
            }
        }
        .more {
            position: absolute;
            font-size: 16px;
            color: #666666;
            letter-spacing: 0.4px;
            line-height: 22px;
            font-weight: 400;
            right: 40px;
            bottom: -30px;
        }
    }
}

.floor-sixth {
    padding-bottom: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #fff;
    .title {
        font-size: 40px;
        color: #333333;
        letter-spacing: 0;
        text-align: center;
        font-weight: 700;
        margin-top: 60px;
        margin-bottom: 60px;
    }
    .content {
        display: flex;
        flex-direction: column;
        .row {
            img {
                width: 20%;
            }
            &:not(:last-child) {
                margin-bottom: 40px;
            }
        }
    }
}

@primary-color: #D7000F;