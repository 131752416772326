.header-warp {
    display: flex;
    flex-direction: column;
    height: 70px;
    background-color: #fff;
    width: 100%;
    .header-container {
        height: 70px;
        min-width: 1280px;
        margin: 0 auto;
        max-width: 1920px;
        height: 100%;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0 36px 0 40px;        
        .left,
        .right {
            display: flex;
            align-items: center;
            height: 70px;
        }
        .right {
            .client {
                font-size: 16px;
                color: #333333;
                letter-spacing: 1.12px;
                font-weight: 400;
                margin-right: 30px;
            }
            .btn-warp {
                cursor: pointer;
                display: flex;
                align-items: center;
                .login {
                    width: 73px;
                    height: 32px;
                    text-align: center;
                    line-height: 32px;
                    font-size: 16px;
                    color: #ffffff;
                    letter-spacing: 0.5px;
                    background-image: linear-gradient(
                        135deg,
                        #ff1414 0%,
                        #ff7538 100%
                    );
                    border-radius: 16px;
                    margin-right: 8px;
                }
                .register {
                    width: 73px;
                    height: 32px;
                    text-align: center;
                    line-height: 32px;
                    font-size: 16px;
                    color: #ff3510;
                    letter-spacing: 0.5px;
                    background: #fffef5;
                    border-radius: 16px;
                    border: 1px solid #ff7538;
                }
            }
        }
    }
}

@primary-color: #D7000F;