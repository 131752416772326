body {
    max-width: 1920px;
    min-width: 1280px;
    margin: 0 auto !important;
    background-color: #f4f5f9;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    padding: 0;
    margin: 0;
}

.app {
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

@primary-color: #D7000F;