.affix {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 0;
    width: 66px;
    .content {
        width: 100%;
        border-radius: 4px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12);
        background: #ffffff;
        .item {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            height: 66px;
            justify-content: center;
            img {
                width: 32px;
                height: 32px;
            }
            span {
                font-size: 12px;
                color: #000000;
                letter-spacing: 0.38px;
                font-weight: 400;
            }
            &:hover {
                background-image: linear-gradient(
                    135deg,
                    #ff1414 0%,
                    #ff7538 100%
                );
                span {
                    color: #ffffff;
                }
            }
        }
    }
    .contact {
        width: 500px;
        p {
            padding: 5px;
        }
    }
}

@primary-color: #D7000F;