.footer-wrap {
    background: #2d2d2d;
    padding: 32px 0 20px;
    color: #cccccc;
    p {
        margin: 0;
    }
    .top {
        display: flex;
        margin-bottom: 70px;
        justify-content: center;
        .group {
            display: flex;
            flex-direction: column;
            .title {
                font-size: 18px;
                color: #ffffff;
                letter-spacing: 0;
                font-weight: 700;
                margin-bottom: 18px;
            }
            .link {
                height: 21px;
                font-family: MicrosoftYaHei;
                font-size: 16px;
                color: #ffffff;
                letter-spacing: 0;
                font-weight: 400;
                &:not(:last-child) {
                    margin-bottom: 12px;
                }
            }
            &:not(:last-child) {
                margin-right: 100px;
            }
        }
    }
    .bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .r-1 {
        }
        .r-2 {
        }
        .r-3 {
            a {
                color: #fff !important;
                &:first-child {
                    margin-right: 20px;
                }
            }
        }
    }
}

@primary-color: #D7000F;