.menu-warp {
    height: 100%;
    .c-menu {
        height: 100%;
        border: none;
        li[class~="ant-menu-item-selected"],
        li[class~="ant-menu-submenu-active"],
        li[class~="ant-menu-submenu-selected"] {
            font-weight: 700;
            color: #e12936 !important;
            &::after {
                border-bottom: 3px solid #d7000f !important;
            }
        }
        li {
            line-height: 70px;
            font-size: 18px;
            &:hover {
                font-weight: 700;
                color: #e12936 !important;
                &::after {
                    border-bottom: 3px solid #d7000f !important;
                }
            }
        }
    }
}
.draw-warp {
    width: 495px !important;
    height: auto !important;
    line-height: normal !important;
    background-color: #fff !important;
    cursor: auto;
    .draw-content {
        display: flex;
        color: #333;
        .left {
            width: 130px;
            padding: 30px;
            border-right: 1px solid rgba(0, 0, 0, 0.15);
            .menu-left {
                font-size: 16px;
                // color: #d7000f;
                letter-spacing: 0;
                text-align: justify;
                font-weight: 700;
                cursor: pointer;
                &:not(:last-child) {
                    margin-bottom: 27px;
                }
                &:hover {
                    color: #d7000f;
                }
            }
        }
        .right {
            padding: 30px;
            display: flex;
            flex: 1;
            flex-direction: column;
            // justify-content: space-around;
            .colum {
                // width: 115px;
                .right-title {
                    // padding-bottom: 15px;
                    font-size: 16px;
                    color: #333333;
                    letter-spacing: 0.5px;
                    margin-bottom: 17px;
                    cursor: pointer;
                    &:hover {
                        color: #d7000f;
                    }
                    // border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                    // font-weight: 700;
                }

                .right-menu-item {
                    font-size: 16px;
                    color: #666666;
                    letter-spacing: 0.5px;
                    font-weight: 400;
                    &:hover {
                        color: #d7000f;
                    }
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}
.sub-menu-text {
    font-size: 16px;
    color: #666666;
    letter-spacing: 0.5px;
    font-weight: 400;
    &:hover {
        color: #d7000f;
    }
}

@primary-color: #D7000F;