.carousel-warp {
    width: 100%;
    .carousel-item {
        position: relative;
        img {
            width: 100%;
        }
        .text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            .text-1 {
                font-size: 48px;
                font-weight: 700;
                color: #fff;
                margin-bottom: 8px;
            }
            .text-2 {
                font-size: 26px;
                color: #fff;
                text-align: center;
                width: 80%;
                margin: 0 auto;
            }
            @media screen and (max-width: 1280px) {
                .text-1 {
                    font-size: 45px;
                }
                .text-2 {
                    font-size: 18px;
                }
            }
            @media screen and (min-width: 1280px) and (max-width: 1920px) {
                .text-1 {
                    font-size: calc(100vw * 66 / 1920);
                }
                .text-2 {
                    font-size: calc(100vw * 26 / 1920);
                }
            }
            @media screen and (min-width: 1920px) {
                .text-1 {
                    font-size: 65px;
                }
                .text-2 {
                    font-size: 26px;
                }
            }
        }
    }
}

@primary-color: #D7000F;